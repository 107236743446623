import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DomainCard from '../DomainCard';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import imageStars from './prem-bg.jpg';

const TextContainer = styled.div`
    max-width: 600px;
    margin: auto;
    h1,h2 {
        margin: 0;
    }
`

const StyledBg = styled.div`
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    /* min-height: 385px; */
    background-color: lightslategrey;
    /* background-image: url(${imageStars}); */
    background-repeat: no-repeat;
    background-size: cover;
`;

const StyledLink = styled(props => <Link {...props} />)`
    color: lightgrey;
    text-transform: uppercase;
    border: 1px solid lightgrey;
    border-radius: 16px;
    padding: 5px 10px;
    text-decoration: none;
    margin-top: 5px;
    margin-bottom: 15px;
    display: inline-block;
    transition: .25s;
    &:hover {
        background-color: white;
        color: grey;
    }
`

const DomainShowcase = ({ children }) => {
  var settings = {
    arrows: false,
    dots: false,
    rows: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    // centerPadding: 10,
    // focusOnSelect: true,
    variableWidth: true,
    pauseOnFocus: false,
    pauseOnHover: true,
    // lazyLoad: 'progressive',
    responsive: false,
    adaptiveHeight: false
  };


  const { allMdx: { edges } } = useStaticQuery(graphql`
    query {
        allMdx {
          edges {
            node {
              fields {
                source
              }
              id
              slug
              frontmatter {
                categories
                domain_name
                title
                current_price
                normal_price
                hide_from_showcase
                cover_image {
                  id
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              body
            }
          }
        }
      }
      
    `)

  const [isMobile, setIsMobile] = useState(true);
  const [isLive, setLive] = useState(true);

  useEffect(() => {
    if (window.innerWidth > 600) setIsMobile(false);
    setLive(true);
  });

  const filteredDomains = edges
    .filter(({ node }) => node.fields.source === "domains")
    .filter(({ node }) => !node.frontmatter.hide_from_showcase);

  if (!filteredDomains) return null;

  return (<StyledBg>
    <TextContainer>{children}</TextContainer>
    {(isMobile || !isLive) ?
      <div>
        {filteredDomains.map(({ node }, i) => {
          if (i < 10) return (
            <DomainCard
              key={i}
              auxStyle={{ margin: '10px' }}
              node={node} />
          )
        }
        )}
      </div>
      :
      <Slider {...settings} >
        {filteredDomains.map(({ node }, i) =>
          <DomainCard
            key={i}
            auxStyle={{ margin: '10px' }}
            node={node} />
        )}
      </Slider>
    }
    <StyledLink to="/premium-domains">View All</StyledLink>
  </StyledBg>)
};

DomainShowcase.propTypes = {};

export default DomainShowcase;