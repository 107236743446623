import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from '../components/IntroBulletBox';
import planeImage from '../images/paper-plane-1.svg';
import stopwatchImage from '../images/stopwatch-3.svg';
import starImage from '../images/star.svg';
import globeImage from '../images/internet.svg';

const ResponsiveContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 20px;
    @media screen and (max-width: 850px){
        /* flex-direction: column; */
        display: block;
    }
    & > div {
        flex: 1;
    }
`
const TwoBy = styled.div`
    display: flex;
    flex-direction: row;
    & > div {
        margin: 10px;
    }
    @media screen and (max-width: 500px){
        /* flex-direction: column; */
        display: block;
    }
    & > div {
        flex: 1;
    }
`

const IntroSection = props => {
    return (
        <ResponsiveContainer>
            <TwoBy>
                <Box
                    iconImg={planeImage}
                    title="SEO-Friendly Domains"
                    content="We build you a list of suggested domain names from thousands of popular web words."
                />
                <Box
                    iconImg={stopwatchImage}
                    title="Super Speedy"
                    content={`Press the "go" button and in seconds get suggestions to help you start your web project.`}
                />

            </TwoBy><TwoBy>
                <Box
                    iconImg={globeImage}
                    title="Pick Your Registrar"
                    content="Choose your favorite registrar from the drop-down menu and clicking a result will bring you there."
                />
                <Box iconImg={starImage}
                    title="More Features Ahead"
                    content="For now, we only deal in the most popular TLD -- .com, but more TLDs and other features are in the works."
                />
            </TwoBy>
        </ResponsiveContainer>

    )
};

IntroSection.propTypes = {};

export default IntroSection;