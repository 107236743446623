import React from 'react';
import styled from 'styled-components';
import balloonGuy from '../images/balloon-guy.svg';

const Columns = styled.div`
    display: flex;
    &:first-child {
        align-items: flex-end;
    }
    flex-direction: row;
    justify-content: space-between;
    @media screen  and (max-width: 1000px) {
        flex-direction: column-reverse;   
    }
`

const TextColumns = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .left {
        padding: 0 15px 0 0;
    }
    .right {
        padding: 0 0 0 15px;
    }
    @media screen  and (max-width: 850px) {
        /* flex-direction: column-reverse;  */
        display: block;
        .left {
        padding: 0;
        }
        .right {
            padding: 0;
        }
    }`

const Container = styled.div`
    max-width: 1230px;
    margin: 20px;
    position: relative;
`

const AuthoritySection = () => {
    return (
        <section id="intro">
            <Container>
                <Columns>
                    <div>
                        {/* <ImgBox width={325} height={300} src={doctor} /> */}
                        <img width={300} src={balloonGuy} style={{ marginRight: '15px' }} />
                        {/* <BalloonGuy /> */}
                    </div>
                    <div>
                        <h1 style={{ margin: 0 }}>Make a Fast Impression</h1>
                        <h2 style={{ margin: '0 0 20px 0' }}>with your own <b>great</b> domain name.</h2>
                        <hr />
                        <TextColumns>
                            <div className="left">
                                <p>
                                    <b>Why purchase a premium domain name?</b>
                                </p>
                                <p>
                                    Your reason for purchasing a premium domain should be the same as purchasing a non-premium domain -- because it's a great domain name for your site.
                                    No matter where it was purchased from, or the amount paid, your domain name should accomplish a few goals.
                                </p><p>
                                    It should give your site authority, be memorable, and preferably be short.
                                    When you're shopping for a new shaver, you probably won't care what discountketodietsupply.com has to say on the matter, but you might click the cleanshaven.com link.
                                </p>

                            </div><div className="right" >
                                <p>
                                    <b>What is a premium domain name? </b>
                                </p><p>                                    Well, by definition, it's just a domain which costs more.   </p>
                                <p>                                    There can be several reasons it's a premium domain.
                                Usually it's because the name is of a very popular generic term -- like Beer.com which sold for $7 million in 2004, or Voice.com which sold for $19 million  just last year.
                                Yes, that's millions of U.S. dollars.
</p><p>
                                    The other main reason it can be more valuable is because it's already registered by someone else.
   </p>
                            </div>
                        </TextColumns>
                    </div>
                </Columns>
            </Container>
        </section>
    )
};

AuthoritySection.propTypes = {};

export default AuthoritySection;