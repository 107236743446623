import React from 'react';
import OutlinedContainer from '../OutlinedContainer';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// const ModdedContainer = styled(props => <OutlinedContainer {...props} />)`
//         // margin-bottom: 15px;
// `
const Img = styled.img`
    display: block;
    height: 75px;
    margin: 0 auto;
    filter: brightness(1.5) contrast(.5);
`

const TwoBy = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    & > div {
        margin: 10px;
    }
    @media screen and (min-width: 500px){
        /* flex-direction: column; */
        display: block;
    }
`


const IntroBulletBox = props => {
    const { iconImg, altText, title, content, children } = props || {};
    return (<OutlinedContainer>
        <TwoBy>
            <Img
                src={iconImg}
                alt={altText || 'icon'}
            />
            <h1 style={{ flex: 1, textAlign: 'center' }}>{title}</h1>
        </TwoBy>
        <p >{content}</p>
    </OutlinedContainer>)
};

IntroBulletBox.propTypes = {};

export default IntroBulletBox;