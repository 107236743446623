import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { registrars, selectRegistrar } from '../../features/app/app-slice';

const { GATSBY_API_URL } = process.env;

const Styled = styled.a`
    cursor: pointer;
    font-weight: 400;
    text-align: center;
    background-color: linen;
    padding: 7px;
    margin-bottom: 5px;
    transition: all .1s;
    // word-spacing: -80%;
    text-decoration: none;
    color: darkslategrey;
    &:hover {
        background-color: cadetblue;
        color: snow;
        border-radius: 5px;
    }
    & b {
        font-weight: 600;
    }
`



function LineItem({ term, isPrefix, rank, searchTerm, fullDomain }) {
    let umami = {
        trackEvent: () => { }
    };
    useEffect(() => {
        if (window?.umami) umami = window.umami;
    });

    const currRegistrar = useSelector(selectRegistrar);
    const genAffLink = () => `${GATSBY_API_URL}/register/${fullDomain}/${currRegistrar.id}`;


    const handleClick = ev => {
        var registrarName = 'Unknown';
        try {
            registrarName = currRegistrar.name;
        } catch (err) { }
        console.log(genAffLink(), registrarName);
        umami.trackEvent(`${registrarName} "${fullDomain}"`, `domList`);
        window.open(genAffLink(), 'reg_pane');
        ev.preventDefault();
    }

    const capper = text => text.split('').map((letter, i) => i === 0 ? letter.toUpperCase() : letter).join('');
    const ucTerm = capper(term);
    const ucSearchTerm = capper(searchTerm);

    return (
        <Styled
            href={`${GATSBY_API_URL}/register/${fullDomain}`}
            target="namecheap_frame"
            onClick={handleClick}
        >
            {
                isPrefix ?
                    <><b>{ucTerm}</b>{ucSearchTerm}.com</> :
                    <>{ucSearchTerm}<b>{ucTerm}</b>.com</>
            }
        </Styled>
    )
}

export default LineItem
