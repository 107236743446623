import React from 'react'
import styled from 'styled-components';

const Styled = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 24px;
    background-color: rgba(0,0,0,.25);
    border-radius: 20px;
    & > div {
        /* text-align: center; */
        font-size: 0.75em;
        color: rgba(0,0,0,.5);
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${({ currentValue }) => currentValue || 0}%;
        /* margin-left: 4px; */
        /* padding-left: 5%; */
        height: 24px;
        background-color: ${({ barColor }) => barColor || 'lightseagreen'};
        border-radius: 20px;
    }
`

function ProgressPill(props) {
    return (
        <Styled {...props}>
            <div>
                {props.label || 'Availability'}
            </div>
        </Styled>
    )
}

export default ProgressPill
// { currentValue, maxValue, barColor, margin }