import React, { useEffect } from 'react';
import AuthoritySection from './AuthoritySection';
import GetStartedSection from './GetStartedSection';
import IntroSection from './IntroSection';
import DomainShowcase from './DomainShowcase';
import Container from './Container';
import Layout from './layout';
import SEO from './seo';
import LineItemList from './LineItemList'
import Loader from './Loader'
import { useSelector, useDispatch } from 'react-redux';
import { selectIsLoading, selectAvailableDomains, clickSearchGo } from '../features/app/app-slice';
import ProgressPill from './ProgressPill';

const App = ({ startSearch }) => {
  const dispatch = useDispatch();
  var searchTerm;
  useEffect(() => {
    // [] implement mechanism to clea search results
    try {
      const location = window.location.toString()
      console.log(location);
      // lookahead not support Safari
      // searchTerm = location.match(/(?<=search\/)[\w|-]+$/)[0];

      if (startSearch && searchTerm) dispatch(clickSearchGo({ searchTerm }));
    } catch (err) { }

  }, [searchTerm])

  const isLoading = useSelector(selectIsLoading);
  const availableDomains = useSelector(selectAvailableDomains);
  const availablePercent = Math.floor(parseInt(availableDomains.length / 5000 * 100));

  return (<Layout>
    <SEO
      title={'Domfeed - How to Find a Great Domain Name.'}
      author="@domfeed"
      imageUrl={'https://domfeed.com/img/front-page-og.jpg'}
      description={`If you're looking for domain name for your project, Domfeed.com is where to find it. Enter a keyword and we'll show you hundreds of available domains to make your project a success.`}
    />
    {isLoading &&
      <Loader />
    }
    {(availableDomains?.length < 1) ?
      <>
        <Container>
          <IntroSection />
        </Container>
        <DomainShowcase >
          <h1>Premium Domain Names</h1>
          <h2>Give your project more traction.</h2>
        </DomainShowcase>
        <Container>
          <AuthoritySection />
        </Container>
        <GetStartedSection />
      </> : <Container>
        <div style={{ margin: '15px 0' }}>
          <ProgressPill currentValue={availablePercent} />
        </div>
        <LineItemList />
      </Container>
    }

  </Layout>)
};


export default App;