import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import girlImage from '../images/girl-looking-distance.jpg';
import girlImageThumb from '../images/th_girl-looking-distance.jpg';
import starIcon from '../images/star-plain.svg';

const BgFilled = styled.div`
    background-image: url(${({ imgName }) => imgName});
    background-size: cover;
    /* min-height: 250px; */
    padding: 30px;
`
const StarsHeader = styled.div`
    text-align: center;
    & img {
        margin: 0 10px;
        height: 50px;
    }
`

const PhantomButton = styled.a`
    display: inline-block;
    margin-top: 25px;
    border: 1px solid ghostwhite;
    padding: 10px 20px;;
    text-transform: uppercase;
    color: ghostwhite;
    border-radius: 30px;
    transition: .25s;
    text-decoration: none;
    &:hover {
        background-color: ghostwhite;
        color: darkgray;
    }
`

const BigH1 = styled.h1`
    font-size: 3em;
    color: ghostwhite;
`

const GetStartedSection = props => {
    const [whichImage, setImage] = useState(girlImageThumb)
    useEffect(() => {
        setImage(girlImage);
    })
    return (
        <BgFilled imgName={whichImage}>
            <div style={{
                textAlign: 'center',
                left: '20%'
            }}>
                <StarsHeader>
                    <img src={starIcon} alt="star" />
                    <img src={starIcon} alt="star" />
                    <img src={starIcon} alt="star" />
                </StarsHeader>
                <BigH1>Before You Can Win,<br />You Must Start.</BigH1>
                <PhantomButton href="#">get started</PhantomButton>
            </div>
        </BgFilled>
    )
};

GetStartedSection.propTypes = {};

export default GetStartedSection;