import React, { useEffect } from 'react'
import LineItem from '../LineItem'
import { useSelector } from 'react-redux';
import { selectAvailableDomains, selectSearchTerm } from '../../features/app/app-slice';

import styled from 'styled-components';

const DomainList = styled.div`
    display: grid;
    grid-gap: .25rem;
    
    @media (min-width: 600px) {
        grid-template-columns: repeat(2, 1fr); 
    }
    @media (min-width: 1000px) {
        grid-template-columns: repeat(3, 1fr); 
    }
  `


function LineItemList() {
    var domainList = useSelector(selectAvailableDomains);
    var searchTerm = useSelector(selectSearchTerm);

    console.log({ domainList, searchTerm });
    return (
        <DomainList>
            {(domainList.length > 0) && domainList.map((el, i) => <LineItem searchTerm={searchTerm} key={i} {...el} />)}
        </DomainList>
    )
}

export default LineItemList
